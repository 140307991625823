/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Logo from 'img/VICT_logo.inline.svg'
import Altlogo from 'img/logo_alt.inline.svg'
import Search from 'img/search.inline.svg'
import WhiteSearch from 'img/white_search.inline.svg'
import WhiteChevron from 'img/white_chevron.inline.svg'

// Components
import NavLink from 'components/shared/NavLink'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ButtonLight from 'components/elements/Buttons/ButtonLight'
import SubMenu from 'components/elements/SubMenu'

// Third Party
import { motion, AnimatePresence } from 'framer-motion'
import styled, { css } from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

const StyledHeader = styled.nav`
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  height: 115px;

  & a {
    color: ${({ theme }) => theme.color.dark};

    &:hover {
      color: ${({ theme }) => theme.color.dark};
    }
  }
`

const Gradient = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 280px;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 60%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 60%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 60%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
`

const PostGradient = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  z-index: 99;
  position: relative;
`

const Brand = styled(NavLink)`
  & > svg {
    height: 25px;
    width: 180px;
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.dark};
  height: 6px;
  width: 60%;
  margin: 5px auto;
  border-radius: 2px;
`

const StyledTopBar = styled.section`
  background-color: ${(props) => props.theme.color.primary};
`

const TopBarContent = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
  type: string
}

const Header: React.FC<HeaderProps> = ({ type, setIsScrollable }) => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      fields: wpComponent(databaseId: { eq: 97 }) {
        header {
          topbar {
            showOnWebsite
            description
          }
          menuHeader {
            link {
              title
              url
            }
            submenuChoice
            submenu {
              subLinks {
                link {
                  title
                  url
                }
                description
              }
              email {
                title
                url
              }
              facebook {
                title
                url
              }
              linkedin {
                title
                url
              }
              phone {
                title
                url
              }
              twitter {
                title
                url
              }
            }
          }
        }
      }

      allWpVacancy {
        totalCount
      }
    }
  `)

  return (
    <>
      {query?.fields?.header?.topbar?.showOnWebsite && (
        <StyledTopBar>
          <div className="container py-2 d-flex flex-wrap align-items-center">
            <svg
              id="Laag_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 29.33 25.33"
              width="20"
              height="20"
            >
              <path
                d="m0,25.33L14.67,0l14.67,25.33H0Zm14.67-4c.38,0,.69-.13.95-.38.26-.26.38-.57.38-.95s-.13-.69-.38-.95c-.26-.26-.57-.38-.95-.38s-.69.13-.95.38c-.26.26-.38.57-.38.95s.13.69.38.95c.26.26.57.38.95.38Zm-1.33-4h2.67v-6.67h-2.67v6.67Z"
                fill="#fff;"
                style={{ fill: '#fff' }}
              />
            </svg>
            <TopBarContent
              content={query?.fields?.header?.topbar?.description}
              className="ps-lg-3 pt-3 pt-lg-0"
            />
          </div>
        </StyledTopBar>
      )}
      <StyledHeader
        className={`${
          query?.fields?.header?.topbar?.showOnWebsite ? 'withTop' : 'noTop'
        }`}
      >
        {
          {
            default: <Gradient />,
            post: <PostGradient />,
          }[type]
        }
        <Container className="container">
          <Brand to="/">
            {
              {
                default: <Logo />,
                post: <Altlogo />,
              }[type]
            }
          </Brand>

          <MenuMobile
            type={type}
            query={query}
            setIsScrollable={setIsScrollable}
            count={query.allWpVacancy.totalCount}
          />
          <MenuDesktop
            type={type}
            query={query}
            count={query.allWpVacancy.totalCount}
          />
        </Container>
      </StyledHeader>
    </>
  )
}

const MenuWrapper = styled(motion.div)`
  background-color: ${(props) => props.theme.color.primary};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 30px 0 0;
  display: flex;
  align-items: center;

  & > ul {
    padding: 0 0 150px 0;
    list-style-type: none;
    text-align: left;
    width: 70%;
    margin: 0 auto;

    & li {
      padding: 0;

      & a {
        font-size: 32px;
        font-weight: ${({ theme }) => theme.font.weight.extraBold};
        font-family: ${({ theme }) => theme.font.family.secondary};
        /* color: ${({ theme }) => theme.color.light}; */
        display: flex;
        padding: 2px 0;
        margin-right: 0;
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.light};
    position: absolute;
    content: ' ';
    height: 33px;
    border-radius: 2px;
    width: 6px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

interface MenuMobileProps {
  query: GatsbyTypes.headerQueryQuery
  setIsScrollable: Function
  type: string
  count: number
}

const MenuMobile: React.FC<MenuMobileProps> = ({
  type,
  query,
  setIsScrollable,
  count,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
      </NavButton>
      <AnimatePresence>
        {isOpen && (
          <MenuWrapper
            initial={{ x: 100, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: { type: 'spring', damping: 20, duration: 0.1 },
            }}
            exit={{ x: 0, opacity: 0, transition: { duration: 0.2 } }}
          >
            <NavButtonClose
              role="button"
              onClick={() => {
                setIsOpen(!isOpen)
                setIsScrollable(true)
              }}
            >
              <NavButtonCloseCross />
            </NavButtonClose>
            <ul>
              <Menu type={type} query={query} count={count} />
            </ul>
          </MenuWrapper>
        )}
      </AnimatePresence>
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin-bottom: 0;
`

const SearchWrapper = styled.li`
  margin-right: 20px;
`

interface MenuDesktopProps {
  query: GatsbyTypes.headerQueryQuery
  type: string
  count: number
}

const MenuDesktop: React.FC<MenuDesktopProps> = ({ type, query, count }) => (
  <StyledMenuDesktop className="d-none d-lg-flex">
    <DesktopMenu type={type} query={query} count={count} />
    <SearchWrapper>
      <NavLink to="/zoeken/">
        {
          {
            default: <Search />,
            post: <WhiteSearch />,
          }[type]
        }
      </NavLink>
    </SearchWrapper>
    <li>
      {
        {
          default: <ButtonPrimary to="/support">Support</ButtonPrimary>,
          post: <ButtonLight to="/support">Support</ButtonLight>,
        }[type]
      }
    </li>
  </StyledMenuDesktop>
)

const Item = styled(motion.li)<{ type: string; career: boolean }>`
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    & > a {
      font-size: 14px !important;
    }
  }

  /* position: relative; */
  & > a {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    margin-right: 40px;
    transition: color 0.3s ease;
    ${({ type }) =>
      type === 'default' &&
      css`
        color: ${(props) => props.theme.color.dark};
      `}

    ${({ type }) =>
      type === 'post' &&
      css`
        color: ${(props) => props.theme.color.light};
      `}

    &:hover {
      @media screen and (min-width: 992px) {
        color: ${(props) => props.theme.color.dark};
        border-bottom: 4px solid ${({ theme }) => theme.color.primary};
      }

      @media screen and (max-width: 991px) {
        color: ${({ theme }) => theme.color.light};
      }
    }

    &[aria-current='page'] {
      @media screen and (min-width: 992px) {
        color: ${({ theme }) => theme.color.dark};
        border-bottom: 4px solid ${({ theme }) => theme.color.primary};
      }

      @media screen and (max-width: 991px) {
        color: ${({ theme }) => theme.color.light};
      }
    }

    &::before {
      content: none;
      position: absolute;
      background-color: ${({ theme }) => theme.color.light};
      height: 0px;
      top: -15px;
      z-index: -1;
      transition: all 0.3s ease;
      ${(props) =>
        props.career
          ? css`
              left: -25%;
              width: 190%;
            `
          : css`
              left: -30%;
              width: 160%;
            `}
    }

    ${({ open }) =>
      open &&
      css`
        @media screen and (min-width: 992px) {
          position: relative;
          color: ${({ theme }) => theme.color.dark} !important;

          &::before {
            content: '';
            height: 75px;
          }
        }

        @media screen and (max-width: 992px) {
          & svg {
            transform: rotate(90deg);
          }
        }
      `};

    @media screen and (max-width: 991px) {
      display: flex;
      justify-content: space-between;
      color: ${({ theme }) => theme.color.primaryLight};

      & svg {
        width: 24px;
        height: 24px;
        transition: all 0.3s ease;
      }
    }
  }

  @media screen and (max-width: 1199px) {
    & > a {
      margin-right: 20px;
    }
  }

  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > a {
      width: 100%;
    }
  }
`

const Amount = styled.div`
  @media screen and (max-width: 1199px) {
    position: absolute;
    top: -10px;
    right: -15px;
  }

  @media (min-width: 1200px) {
    position: absolute;
    right: -25px;
    top: 3px;
    bottom: 0;
  }

  @media (max-width: 991px) {
    margin-left: 1rem;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }

  margin-top: auto;
  margin-bottom: auto;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.secondary};
  font-size: 12px;

  @media screen and (max-width: 991px) {
    background-color: ${({ theme }) => theme.color.light};
    color: ${({ theme }) => theme.color.primary};
    font-size: 16px;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    right: 0;
  }
`

interface MenuProps {
  query: GatsbyTypes.headerQueryQuery
  type: string
  count: number
}

const ChevronContainer = styled.div``

const Menu: React.FC<MenuProps> = ({ query: { fields }, type, count }) => {
  const [open, setOpen] = React.useState(-1)

  return (
    <>
      {fields?.header?.menuHeader?.map((menuItem, index: number) => (
        <>
          <Item
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${index}-menu-header`}
            type={type}
            career={menuItem?.link?.title === 'Carrière'}
            className={menuItem?.submenuChoice ? `` : `position-relative`}
            style={
              menuItem?.link?.title === 'Carrière'
                ? { marginRight: 24 }
                : { marginRight: 0 }
            }
          >
            <NavLink
              className="position-relative"
              to={menuItem?.link?.url || '/'}
            >
              {menuItem?.link?.title}
              {menuItem?.submenuChoice && (
                <motion.div
                  className="d-lg-none d-block"
                  onHoverStart={() => menuItem?.submenuChoice && setOpen(index)}
                  onHoverEnd={() => menuItem?.submenuChoice && setOpen(-1)}
                  onTap={() =>
                    menuItem?.submenuChoice &&
                    setOpen(index === open ? -1 : index)
                  }
                  open={menuItem?.submenuChoice && open === index}
                >
                  <ChevronContainer>
                    <WhiteChevron />
                  </ChevronContainer>
                </motion.div>
              )}
              {menuItem?.link?.title === 'Carrière' && <Amount>{count}</Amount>}
            </NavLink>
            {menuItem?.submenuChoice && (
              <AnimatePresence>
                {open === index && (
                  <SubMenu
                    title={menuItem.link?.title || ''}
                    fields={menuItem.submenu}
                  />
                )}
              </AnimatePresence>
            )}
          </Item>
        </>
      ))}
    </>
  )
}

interface DesktopMenuProps {
  query: GatsbyTypes.headerQueryQuery
  type: string
  count: number
}

const DesktopMenu: React.FC<DesktopMenuProps> = ({
  query: { fields },
  type,
  count,
}) => {
  const [open, setOpen] = React.useState(-1)

  return (
    <>
      {fields?.header?.menuHeader?.map((menuItem, index: number) => (
        <React.Fragment key={`${index}-menu-header`}>
          <Item
            onHoverStart={() => menuItem?.submenuChoice && setOpen(index)}
            onHoverEnd={() => menuItem?.submenuChoice && setOpen(-1)}
            onTap={() =>
              menuItem?.submenuChoice && setOpen(index === open ? -1 : index)
            }
            open={menuItem?.submenuChoice && open === index}
            type={type}
            career={menuItem?.link?.title === 'Carrière'}
            className={menuItem?.submenuChoice ? `` : `position-relative`}
            style={
              menuItem?.link?.title === 'Carrière'
                ? { marginRight: 24 }
                : { marginRight: 0 }
            }
          >
            <NavLink
              className="position-relative"
              to={menuItem?.link?.url || '/'}
            >
              {menuItem?.link?.title}
              {menuItem?.submenuChoice && (
                <motion.div className="d-lg-none d-block">
                  <ChevronContainer>
                    <WhiteChevron />
                  </ChevronContainer>
                </motion.div>
              )}
              {menuItem?.link?.title === 'Carrière' && <Amount>{count}</Amount>}
            </NavLink>
            {menuItem?.submenuChoice && (
              <AnimatePresence>
                {open === index && (
                  <SubMenu
                    title={menuItem.link?.title || ''}
                    fields={menuItem.submenu}
                  />
                )}
              </AnimatePresence>
            )}
          </Item>
        </React.Fragment>
      ))}
    </>
  )
}

export default Header
