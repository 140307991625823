import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

// Images
import Logo from 'img/VICT_logo.inline.svg'
import Chevron from 'img/green_chevron.inline.svg'
import Facebook from 'img/facebook.inline.svg'
import Linkedin from 'img/linkedin.inline.svg'
import X from 'img/x.inline.svg'
import Instagram from 'img/instagram.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import { ButtonOutlineCSS } from 'components/elements/Buttons/ButtonOutline'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'
import FormDuuf from 'components/related/Form'
import Plaatjie from '@ubo/plaatjie'

const StyledFooter = styled.div`
  padding-top: 40px;
  background-color: ${(props) => props.theme.color.secondary};
  color: ${(props) => props.theme.color.dark};
  border-top-left-radius: 125px;
  max-width: 2000px;
  margin: 0 auto;

  & ul {
    padding-left: 0;
  }

  & li {
    list-style: none;
    font-size: 16px;
    line-height: 34px;

    &:hover {
      text-decoration: underline;
    }
  }

  & h2 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: 22px;
    line-height: 26px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding-bottom: 20px;
  }

  & h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: 22px;
    line-height: 26px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding-bottom: 0px;
  }

  & a {
    color: ${({ theme }) => theme.color.dark};

    &:hover {
      color: ${({ theme }) => theme.color.dark};
    }
  }

  @media screen and (max-width: 991px) {
    border-top-left-radius: 25px;
    padding-top: 0;
  }
`

const LogoWrapper = styled.div`
  padding-bottom: 28px;
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-weight: ${({ theme }) => theme.font.weight.medium};

  &:hover {
    text-decoration: underline;
  }

  & svg {
    margin-left: 0.5rem;
  }
`

const IconLink = styled.a`
  margin-right: 0.5rem;
  padding-bottom: 5px;

  & svg {
    path {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
`

const IconGreenLink = styled.a`
  margin-right: 0.5rem;
  padding-bottom: 5px;
`

const PrivacyLink = styled(Link)`
  padding: 0 20px;

  &:hover {
    text-decoration: underline;
  }
`

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 1.5rem;
    max-width: 400px;
    position: relative;

    &-fileupload {
      display: flex;
      justify-content: flex-end;
      background-color: ${({ theme }) => theme.color.light};
      border-radius: 10px;
      padding: 10px;

      &::before {
        content: 'Upload je CV';
        position: absolute;
        font-size: 16px;
        color: ${({ theme }) => theme.color.grey};
        top: 13px;
        left: 15px;
      }
    }
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-radius: 10px;
    border: none;
    min-height: 50px;
    padding: 0 15px;
    font-size: 16px;

    &::placeholder {
      color: ${({ theme }) => theme.color.grey};
    }
  }

  & .form-duuf-textarea {
    padding: 15px;
    height: 160px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};

    & button {
      font-size: 12px;
      border-radius: 5px;
      font-weight: 200;
      padding: 8px 12px;
      min-width: unset;
    }
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
    position: absolute;
    right: 15px;
    top: 13px;
    font-size: 14px;

    & + input,
    + textarea {
      border: 1px solid tomato;
    }
  }

  & .form-duuf-submit {
    ${ButtonOutlineCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.primary};
    font-size: 20px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & .form-duuf-something-wrong {
    color: tomato;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  width: 180px;
  height: 74px;
  margin-left: 1rem;
  margin-bottom: -15px;
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menufooter {
            link {
              title
              url
            }
          }
          menuinfo {
            description
            phone {
              title
              url
            }
            email {
              title
              url
            }
            support {
              title
              url
            }
            facebook {
              title
              url
            }
            linkedin {
              title
              url
            }
            twitter {
              title
              url
            }
            instagram {
              title
              url
            }
            privacy {
              title
              url
            }
            algemeneVoorwaarden {
              title
              url
            }
            smallDescription
            smallImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, width: 600)
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <StyledFooter>
      <div className="container py-5">
        <div className="row pb-5">
          <div className="col-lg-3 pb-lg-0 pb-4">
            <LogoWrapper>
              <Logo />
            </LogoWrapper>

            <div>
              <ParseContent content={fields?.footer?.menuinfo?.description} />
            </div>

            <div className="py-4">
              <div>
                <StyledLink to={fields?.footer?.menuinfo?.phone?.url || '/'}>
                  {fields?.footer?.menuinfo?.phone?.title}
                  <Chevron />
                </StyledLink>
              </div>
              <div>
                <StyledLink to={fields?.footer?.menuinfo?.email?.url || '/'}>
                  {fields?.footer?.menuinfo?.email?.title}
                  <Chevron />
                </StyledLink>
              </div>
            </div>

            <div className="pt-3">
              <ButtonPrimary to={fields?.footer?.menuinfo?.support?.url || '/'}>
                {fields?.footer?.menuinfo?.support?.title}
              </ButtonPrimary>
            </div>
          </div>
          <div className="col-lg-6 pb-lg-0 pb-4">
            <h2>Menu</h2>
            <ul>
              {fields?.footer?.menufooter?.map(
                ({ link: { url, title } }: any, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>
                    <Link to={url}>{title}</Link>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="col-lg-3">
            <h3>Blijf op de hoogte en schrijf je in voor onze kennisupdate</h3>
            <div>
              <StyledFormDuuf id={3} className="mt-4 pt-2" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="d-flex h-100 align-items-end">
              <IconLink
                target="__blank"
                href={fields?.footer?.menuinfo?.facebook?.url}
              >
                <Facebook />
              </IconLink>
              <IconLink
                target="__blank"
                href={fields?.footer?.menuinfo?.linkedin?.url}
              >
                <Linkedin />
              </IconLink>
              <IconGreenLink
                target="__blank"
                href={fields?.footer?.menuinfo?.instagram?.url}
              >
                <Instagram />
              </IconGreenLink>
              <PrivacyLink to={fields?.footer?.menuinfo?.privacy?.url || '/'}>
                {fields?.footer?.menuinfo?.privacy?.title}
              </PrivacyLink>
              <PrivacyLink to={fields?.footer?.menuinfo?.algemeneVoorwaarden?.url || '/'} target="_blank">
                {fields?.footer?.menuinfo?.algemeneVoorwaarden?.title}
              </PrivacyLink>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex justify-content-end align-items-end">
              <ParseContent
                content={fields?.footer?.menuinfo?.smallDescription}
              />
              <StyledPlaatjie
                image={fields?.footer?.menuinfo?.smallImage}
                alt="partner-image"
              />
            </div>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
