import React from 'react'
import { Link } from 'gatsby'

// Components
import ParseContent from 'components/shared/ParseContent'

// Images
import Facebook from 'img/facebook.inline.svg'
import Twitter from 'img/twitter.inline.svg'
import Linkedin from 'img/linkedin.inline.svg'
import WhiteChevron from 'img/white_chevron.inline.svg'
import GreenChevron from 'img/green_chevron.inline.svg'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

interface Props {
  fields: any
  title: string
}

const StyledSubMenu = styled(motion.div)`
  position: absolute;
  padding-top: 30px;
  top: 70px;
  left: 0;
  display: flex;
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  padding: 60px 50px 80px 50px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;

  @media screen and (min-width: 992px) {
    width: 660px;
  }

  @media screen and (min-width: 1200px) {
    width: 840px;
  }

  @media screen and (min-width: 1400px) {
    width: 1020px;
  }
`

const BlockContent = styled.div`
  & a {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }
  }

  & p {
    color: ${({ theme }) => theme.color.grey};
    text-align: justify;
    padding-right: 50px;
  }

  & svg {
    margin-left: 0.5rem;
  }
`

const BlockTitle = styled.div`
  font-size: 26px;
  font-family: ${({ theme }) => theme.font.family.secondary};
  color: ${({ theme }) => theme.color.dark};
  opacity: 0.25;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

const AltBlockTitle = styled.div`
  font-size: 26px;
  font-family: ${({ theme }) => theme.font.family.secondary};
  color: ${({ theme }) => theme.color.light};
  opacity: 0.25;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

const SubMenuContact = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  padding: 60px 50px 80px 50px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
`

const BigLink = styled.div`
  padding-bottom: 10px;
  & a {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.darker};
    font-family: ${({ theme }) => theme.font.family.secondary};
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }
  }

  & svg {
    margin-left: 1rem;
  }
`

const StyledMobileSubMenu = styled(motion.div)`
  position: relative;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.light};
  overflow: hidden;
  width: 100%;
  z-index: 9999;
`

const MobileSubMenuBlock = styled.div`
  & a {
    color: ${({ theme }) => theme.color.dark} !important;
    font-size: 18px !important;
    display: flex;
    align-items: center;
  }

  & svg {
    margin-left: 1rem;
  }
`

const SubMenu: React.FC<Props> = ({ fields, title }) => (
  <>
    <StyledSubMenu
      className="d-lg-flex d-none"
      initial={{ y: -50, opacity: 0, pointerEvents: 'none' }}
      animate={{ y: 0, opacity: 1, pointerEvents: 'all' }}
      exit={{ y: 50, opacity: 0, pointerEvents: 'none' }}
    >
      <Block>
        <BlockTitle className="pb-4 mb-2">{title}</BlockTitle>
        <div className="row">
          {fields.subLinks.map((sublink: any, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <BlockContent key={`${index}-submenu`} className="col-4">
              <div className="pb-3">
                <Link to={sublink.link.url}>
                  {sublink.link.title}
                  <GreenChevron />
                </Link>
              </div>

              <ParseContent content={sublink.description} />
            </BlockContent>
          ))}
        </div>
      </Block>
      <SubMenuContact>
        <AltBlockTitle className="pb-4 mb-2">contact</AltBlockTitle>
        <BigLink>
          <a href={fields.phone.url}>
            {fields.phone.title}
            <WhiteChevron />
          </a>
        </BigLink>
        <BigLink>
          <a href={fields.email.url}>
            {fields.email.title}
            <WhiteChevron />
          </a>
        </BigLink>
        <div className="d-flex pt-3 align-items-center">
          <div className="me-2">
            <a href={fields.facebook.url}>
              <Facebook />
            </a>
          </div>
          <div className="me-2">
            <a href={fields.twitter.url}>
              <Twitter />
            </a>
          </div>
          <a href={fields.linkedin.url}>
            <Linkedin />
          </a>
        </div>
      </SubMenuContact>
    </StyledSubMenu>
    <StyledMobileSubMenu
      initial={{ height: 0 }}
      animate={{ height: 'fit-content' }}
      exit={{ height: 0 }}
      className="d-lg-none d-block"
    >
      <div className="px-4 py-3">
        {fields.subLinks.map((sublink: any) => (
          <MobileSubMenuBlock className="py-1">
            <Link to={sublink.link.url}>
              {sublink.link.title}
              <GreenChevron />
            </Link>
          </MobileSubMenuBlock>
        ))}
      </div>
    </StyledMobileSubMenu>
  </>
)

export default SubMenu
