import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// Theme
import Theme from 'styles/Global'

// Components
import Footer from 'components/layout/Footer'
import Header from 'components/layout/Header'

// CSS
import 'styles/all.css'

export interface LayoutContextProps {
  title: string
  locale: string
  translations: Array<{
    href: string
    locale: string
  }>
  setIsScrollable: Function
}

export const LayoutContext = React.createContext({
  title: '',
  locale: 'nl_NL',
  translations: [],
  setIsScrollable: (bool: boolean) => bool,
})

const Content = styled.div<{ isSticky?: boolean }>`
  position: relative;
  top: -115px;
`

interface LayoutProps {
  title?: string
  children: any
  locale?: string
  translations?: any
  type?: 'default' | 'post'
}

const Layout: React.FC<LayoutProps> = ({
  title = '',
  children,
  locale,
  translations,
  type = 'default',
}) => {
  const [isSticky, setSticky] = useState<boolean>(false)
  const [isScrollable, setIsScrollable] = useState<boolean>(true)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <Theme isScrollable={isScrollable}>
      <LayoutContext.Provider
        // @ts-ignore
        value={{ title, setIsScrollable, locale, translations }}
      >
        <Header
          type={type}
          isSticky={isSticky}
          setIsScrollable={setIsScrollable}
        />
        <Content isSticky={isSticky}>{children}</Content>
        <Footer />
      </LayoutContext.Provider>
    </Theme>
  )
}

export default Layout
