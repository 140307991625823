const mainTheme = {
  color: {
    primary: '#76B459',
    primaryDark: '#527E3E',
    primaryLight: '#DCE9D2',
    secondary: '#E5E5E5',
    grey: '#9A9A9A',
    greyLight: '#F0F0F0',
    dark: '#565656',
    light: '#FFFFFF',
    darkish: '#4D4D4C',
    darker: '#232323',
  },

  font: {
    family: {
      primary: 'PT Sans',
      secondary: 'Sequel Sans',
    },

    size: {
      tiny: '10px',
      small: '14px',
      medium: '16px',
      big: '18px',
      large: '24px',
      huge: '30px',
      mega: '40px',
      giga: '60px',
    },

    weight: {
      light: 300,
      regular: 400,
      medium: 400,
      semiBold: 600,
      semiBoldHead: 600,
      bold: 700,
      boldHead: 600,
      boldBody: 700,
      extraBold: 800,
    },
  },
}

export default mainTheme
