import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

export const ButtonPrimaryCSS = css`
  display: inline-block;

  & > a,
  & > button {
    background-color: ${({ theme }) => theme.color.primary};
    color: ${(props) => props.theme.color.light} !important;
    padding: 15px 30px;
    width: 100%;
    min-width: 160px;
    height: 100%;
    display: block;
    text-align: center;
    border-radius: 62px;
    transition: all 0.3s ease;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};

    &:hover {
      background-color: ${({ theme }) => theme.color.primaryDark};
      color: ${({ theme }) => theme.color.light};
    }
  }
`

const StyledButton = styled.span`
  ${ButtonPrimaryCSS}
`

interface ButtonPrimaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  children: any
  className?: string
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonPrimary
