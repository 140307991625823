import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import parse, { domToReact } from 'html-react-parser'
import styled from 'styled-components'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import Plaatjie from '@ubo/plaatjie'

const Button = styled.button`
   background-color: ${({ theme }) => theme.color.primary};
  color: ${(props) => props.theme.color.light} !important;
  padding: 15px 30px;
  width: 250px;
  height: 100%;
  display: block;
  text-align: center;
  border-radius: 62px;
  transition: all 0.3s ease;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-family: ${({ theme }) => theme.font.family.secondary};
  display: inline-block;

    &:hover {
      background-color: ${({ theme }) => theme.color.primaryDark};
      color: ${({ theme }) => theme.color.light};
    }
`

const Content = styled.div`
  font-weight: ${(props) => props.theme.font.weight.light};
  font-size: 16px;

  & strong,
  & b {
    font-weight: ${(props) => props.theme.font.weight.bold};
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    text-align: initial;
    font-weight: ${(props) => props.theme.font.weight.light};

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    @media (min-width: 992px) {
      font-size: 45px;
      line-height: 45px;
    }

    @media (max-width: 991px) {
      font-size: 32px;
      line-height: 32px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & ul {
    & li {
    }
  }

  & p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  & a {
  }

  & img {
  }

  /*
    WP WYSIWYG
  */
  .alignright {
    float: right;
    text-align: right;
  }

  .aligncenter {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .wp-caption {
    max-width: 100%;
  }

  .wp-caption-text {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }
`

const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const convertCSSKey = (key: string) => {
  const keySplit = key.split('-')
  let newKey = ''

  keySplit.forEach((value, index) => {
    let newValue = value

    if (index > 0) {
      newValue = capitalize(value)
    }

    newKey += newValue
  })

  return newKey
}

const findAndReplace = (content: any, settings: any) =>
  parse(content, {
    replace: (domNode: any) => {
      const { name, attribs, children }: any = domNode

      if (name === 'a' && attribs.class === 'button-primary') {
        return (
          <ButtonPrimary to={domNode.attribs.href}>
            {domToReact(domNode.children, {})}
          </ButtonPrimary>
        )
      }

      if (name === 'button' && attribs.class === 'button-primary') {
        return (
          <Button type="submit">
            {domToReact(domNode.children, {})}
          </Button >
        )
      }

      // if (children && children.length > 0) {
      //   const html = renderToString(
      //     domToReact(domNode.children, {}) as DOMElement<any, any>
      //   )
      //   const htmlFindAndReplace = findAndReplace(html, settings)

      //   const attributes = domNode.attribs

      //   if (
      //     attributes.src &&
      //     attributes.src.indexOf(settings.sourceUrl) === -1
      //   ) {
      //     attributes.src = `${settings.sourceUrl}${attributes.src}`
      //   }

      //   if (attributes.href) {
      //     if (
      //       attributes.href.indexOf('www.') !== -1 ||
      //       attributes.href.indexOf('http:') !== -1 ||
      //       attributes.href.indexOf('https:') !== -1
      //     ) {
      //       attributes.target = '_blank'
      //     } else if (
      //       attributes.href.indexOf('/wp-content/') !== -1 &&
      //       attributes.href.indexOf(settings.sourceUrl) === -1
      //     ) {
      //       attributes.href = `${settings.sourceUrl}${attributes.href}`
      //     }
      //   }

      //   attributes.className = attributes.class

      //   delete attributes.class

      //   if (attributes.style) {
      //     attributes.style = convertCSS(attributes.style)
      //   }

      //   const Comp = domNode.name

      //   return (
      //     <Comp
      //       src={attributes.src}
      //       className={attributes.className}
      //       style={attributes.style}
      //       id={attributes.id}
      //       href={attributes.href}
      //       target={attributes.target}
      //     >
      //       {htmlFindAndReplace}
      //     </Comp>
      //   )
      // }

      if (name === 'a') {
        const attributes = domNode.attribs

        if (attributes.style) {
          delete attributes.style
        }

        if (
          attributes.href.indexOf('www.') !== -1 ||
          attributes.href.indexOf('http:') !== -1 ||
          attributes.href.indexOf('https:') !== -1
        ) {
          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <a {...attributes} target="_blank">
              {domToReact(domNode.children, {})}
            </a>
          )
        }

        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Link to={attributes.href} {...attributes}>
            {domToReact(domNode.children, {})}
          </Link>
        )
      }

      return null
    },
  })

interface ParseContentProps {
  content: any
  className?: string
}

const ParseContent: React.FC<ParseContentProps> = ({
  content,
  className = '',
}) => {
  // eslint-disable-next-line
  const { site } = useStaticQuery<GatsbyTypes.ParseContentQueryQuery>(graphql`
    query ParseContentQuery {
      site {
        siteMetadata {
          siteWpUrl
        }
      }
    }
  `)

  let sourceUrl = ''

  if (site && site.siteMetadata && site.siteMetadata.siteWpUrl) {
    sourceUrl = site.siteMetadata.siteWpUrl
  }

  if (!content) {
    return null
  }

  return (
    <Content className={className}>
      {findAndReplace(content, { sourceUrl })}
    </Content>
  )
}

export default ParseContent
