import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

export const ButtonOutlineCSS = css`
  display: inline-block;

  & > a,
  & > button {
    background-color: transparent;
    color: ${(props) => props.theme.color.dark};
    border: 2px solid ${({ theme }) => theme.color.dark};
    padding: 10px 15px;
    width: 100%;
    min-width: 160px;
    height: 100%;
    display: block;
    text-align: center;
    border-radius: 62px;
    transition: all 0.3s ease;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};

    &:hover {
      background-color: ${({ theme }) => theme.color.primary};
      color: ${({ theme }) => theme.color.light};
      border: 2px solid ${({ theme }) => theme.color.primary};

      & svg {
        path {
          fill: ${({ theme }) => theme.color.light};
        }
      }
    }

    & svg {
      path {
        fill: ${({ theme }) => theme.color.dark};
      }
    }
  }
`

const StyledButton = styled.span`
  ${ButtonOutlineCSS}
`

interface ButtonOutlineProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  children: any
  className?: string
}

const ButtonOutline: React.FC<ButtonOutlineProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonOutline
